import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { APIMutationOptions, useAPIMutation } from '../../api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from '../../api/APIQuery';
import { requestCarrierAPI } from '../../api/CarrierAPIClient';
import {
  BillingAddressDTO,
  SubscriptionDetailsDTO,
  TaxPriceDTO,
  UpdateSubscriptionDTO,
} from './SubscriptionDTO';

export function useSubscriptionAPI() {
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateSubscriptionQueries();
  const invalidateSubscriptionDetails = useInvalidateSubscriptionQueries();

  return useMemo(
    () => ({
      updateSubscriptionSettings: (json: UpdateSubscriptionDTO) =>
        requestCarrierAPI('POST /internal/web/settings/subscription/', {
          json,
        }).then((res) => {
          invalidateQueries();
          return res;
        }),

      saveBillingAddress: (json: BillingAddressDTO) =>
        requestCarrierAPI('POST /internal/web/carrier/billing-address/', {
          json,
        }).then((res) => {
          void queryClient.invalidateQueries(['settings', 'billingAddress']);
          return res;
        }),

      reactivateSubscription: () =>
        requestCarrierAPI(
          'POST /internal/web/settings/subscription/reactivate/',
        ).then((res) => {
          invalidateQueries();
          return res;
        }),
      upgradeSeats: (seats_count: number) =>
        requestCarrierAPI(
          'POST /internal/web/settings/subscription/upgrade-seats/',
          {
            json: { seats_count },
          },
        ).then((res) => {
          void queryClient.invalidateQueries(['settings', 'subscription']);
          invalidateSubscriptionDetails();
          invalidateQueries();
          return res;
        }),
    }),
    [queryClient, invalidateQueries, invalidateSubscriptionDetails],
  );
}

export function useUpdateSubscriptionSettingsMutation(
  options?: APIMutationOptions<UpdateSubscriptionDTO>,
) {
  const { updateSubscriptionSettings } = useSubscriptionAPI();
  const invalidateQueries = useInvalidateSubscriptionQueries();
  return useAPIMutation(
    (json: UpdateSubscriptionDTO) =>
      updateSubscriptionSettings(json).then((res) => {
        invalidateQueries();
        return res;
      }),
    options,
  );
}

export function useReactivateSubscriptionMutation(
  options?: APIMutationOptions,
) {
  const { reactivateSubscription } = useSubscriptionAPI();

  return useAPIMutation(reactivateSubscription, options);
}

export function useSubscriptionDetails(
  options?: APIQueryOptions<SubscriptionDetailsDTO>,
): APIQueryResult<SubscriptionDetailsDTO> {
  return useAPIQuery(
    ['settings', 'subscription-details'],
    () => requestCarrierAPI('GET /internal/web/settings/subscription/details/'),
    options,
  );
}

interface EstimateTaxPriceParams {
  seats_count?: number;
  /**
   * When true, response will include both total and sub_total amounts
   */
  include_total_invoice?: boolean;
}

export function useEstimateTaxPrice(
  params: EstimateTaxPriceParams,
  options?: APIQueryOptions<TaxPriceDTO>,
): APIQueryResult<TaxPriceDTO> {
  return useAPIQuery(
    ['settings', 'estimateTaxPrice', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/settings/subscription/estimate-invoice/{?params*}',
        { params },
      ]),
    options,
  );
}

export function useEstimateSeatsUpgrade(
  seats_count?: number,
  options?: APIQueryOptions<TaxPriceDTO>,
): APIQueryResult<TaxPriceDTO | null> {
  return useAPIQuery(
    ['settings', 'estimateSeatsUpgrade', { seats_count }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/settings/subscription/estimate-seats-upgrade/?seats_count={seats_count}',
        { seats_count },
      ]),
    options,
  );
}

export function useBillingAddress(
  options?: APIQueryOptions<BillingAddressDTO>,
): APIQueryResult<BillingAddressDTO> {
  return useAPIQuery(
    ['settings', 'billingAddress'],
    () => requestCarrierAPI('GET /internal/web/carrier/billing-address/'),
    options,
  );
}

interface SubscriptionBillableSeatsParams {
  measurement_type?: string;
}

export function useSubscriptionBillableSeats(
  params: SubscriptionBillableSeatsParams,
  options?: APIQueryOptions<{ billable_seats: number; reserved_seats: number }>,
): APIQueryResult<{ billable_seats: number; reserved_seats: number }> {
  return useAPIQuery(
    ['settings', 'subscription-billable-seats', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/settings/subscription/billable-seats/{?params*}',
        { params },
      ]),
    { ...options, enabled: !!params.measurement_type },
  );
}

export function useSubscriptionDetailsMutation() {
  return useAPIMutation<undefined, SubscriptionDetailsDTO>(() =>
    requestCarrierAPI('GET /internal/web/settings/subscription/details/'),
  );
}

export function useSaveBillingAddressMutation(
  options?: APIMutationOptions<BillingAddressDTO>,
) {
  const { saveBillingAddress } = useSubscriptionAPI();
  return useAPIMutation((json) => saveBillingAddress(json), options);
}

export function useInvalidateSubscriptionQueries() {
  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    void queryClient.invalidateQueries(['settings', 'subscription']);
    void queryClient.invalidateQueries(['settings', 'subscription-details']);
    void queryClient.invalidateQueries(['settings', 'estimateTaxPrice']);
    void queryClient.invalidateQueries([
      'settings',
      'subscription-billable-seats',
    ]);
    void queryClient.invalidateQueries(['settings', 'carrier']);
  };

  return invalidateQueries;
}

export function useSubscription() {
  const {
    data: subscription,
    refetch: refetchSubscriptionSettings,
    isInitialLoading: isSubscriptionSettingsLoading,
    isLoading,
    error,
    isFetching,
  } = useSubscriptionDetails();

  const isSubscriptionOnLitePlan =
    subscription?.subscription?.plan?.name === 'lite';
  const isSubscriptionInTrial =
    subscription?.subscription?.status === 'in_trial';
  const measurementType = isSubscriptionOnLitePlan
    ? subscription?.upgrade_option?.plan?.measurement_type
    : subscription?.subscription?.plan?.measurement_type;
  const isDriverSeat = measurementType === 'driver_seat';
  const seatType = isDriverSeat ? 'driver' : 'seat';

  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: measurementType,
  });
  return {
    error,
    seatType,
    isLoading,
    isFetching,
    subscription,
    isDriverSeat,
    billableSeats,
    isSubscriptionInTrial,
    isSubscriptionOnLitePlan,
    refetchSubscriptionSettings,
    isSubscriptionSettingsLoading,
  };
}
