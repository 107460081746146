import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trackEvent } from 'shared/helpers/Analytics';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useSubscriptionCelebrationState } from 'shared/modules/subscription/core/useSubscriptionCelebrationState';
import {
  useBillingAddress,
  useSubscription,
} from 'shared/modules/subscription/SubscriptionAPI';
import {
  BillingLocationState,
  SubscriptionEditBillingAddressDrawer,
} from 'shared/modules/subscription/SubscriptionEditBillingAddressDrawer';
import { SubscriptionReactivationDrawer } from 'shared/modules/subscription/SubscriptionReactivationDrawer';
import {
  LocationState,
  SubscriptionSalesTaxDrawer,
} from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import {
  SubscriptionUpdateCardDetailsDrawer,
  SubscriptionUpdateCardDetailsDrawerProps,
} from 'shared/modules/subscription/SubscriptionUpdateCardDetailsDrawer';
import { SettingsPageLayout } from './core/SettingsPageLayout';
import { SubscriptionCelebration } from './seat-based-subscription/SubscriptionCelebration';
import { SubscriptionDetailsCard } from './seat-based-subscription/SubscriptionDetailsCard';
import { SubscriptionPaymentForm } from './seat-based-subscription/SubscriptionPaymentForm';

function SubscriptionUpdateCardDetailsDrawerRoute(
  props: Omit<SubscriptionUpdateCardDetailsDrawerProps, 'open' | 'onClose'>,
) {
  const navigate = useNavigate();
  const { action } = useParams();
  const isEditCardDetailsDrawerOpen = action === 'update_payment_info';

  return (
    <SubscriptionUpdateCardDetailsDrawer
      {...props}
      open={isEditCardDetailsDrawerOpen}
      onClose={() => {
        navigate('/settings/subscription', { replace: true });
      }}
    />
  );
}

interface SubscriptionUpgradePlanDrawerRouteProps {
  refetch: () => void;
}

function SubscriptionUpgradePlanDrawerRoute({
  refetch,
}: SubscriptionUpgradePlanDrawerRouteProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const isUpgradePlanDrawerOpen = action === 'upgrade_plan';
  const { setBannerStatus } = useSubscriptionCelebrationState();

  const state = location.state as LocationState | undefined;

  if (state && isUpgradePlanDrawerOpen) {
    const { billingAddress, cardDetails, token, vaultToken, seatsCount } =
      state;

    return (
      <SubscriptionSalesTaxDrawer
        seatsCount={seatsCount}
        open={isUpgradePlanDrawerOpen}
        token={token}
        vaultToken={vaultToken}
        billingAddress={billingAddress}
        cardDetails={cardDetails}
        onClose={() => {
          navigate('/settings/subscription', {
            replace: true,
            state: undefined,
          });
        }}
        onSubmitSuccess={() => {
          refetch();
          setBannerStatus('unseen');
        }}
      />
    );
  }

  return null;
}

function LoadingCard() {
  return (
    <Card aria-label="Plan">
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="bottom">
            <Typography display="inline" variant="h3">
              <Skeleton width={200} />
            </Typography>
          </Inline>
          <Stack space={['small', 'xsmall']}>
            <Skeleton width={240} />
            <Skeleton />
            <Skeleton />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

function EditBillingAddress() {
  const location = useLocation();
  const navigate = useNavigate();
  const { refetch } = useBillingAddress();
  const { action } = useParams();
  const isEditBillingAddressDrawerOpen = action === 'edit_billing_address';

  const state = location.state as BillingLocationState | undefined;

  return (
    <SubscriptionEditBillingAddressDrawer
      open={isEditBillingAddressDrawerOpen}
      enableReactivation={state?.enableReactivation}
      canAddBilling={state?.canAddBilling}
      onClose={() => {
        navigate('/settings/subscription', { replace: true });
      }}
      onSubmitSuccess={() => {
        void refetch();
      }}
    />
  );
}

export function SettingsSubscriptionPage() {
  const [{ utm_source }] = useQuery();
  const {
    isLoading,
    isFetching,
    subscription,
    isDriverSeat,
    error: subscriptionError,
    refetchSubscriptionSettings,
  } = useSubscription();
  const navigate = useNavigate();
  const { action } = useParams();
  const { showLauncher, hideLauncher } = useCustomerSupport();
  const isEditCardDetailsDrawerOpen = action === 'edit_card_details';
  const isReactivatePlanDrawerOpen = action === 'reactivate_plan';

  useErrorSnackbar(subscriptionError, {
    formatError: (error: Error) =>
      `Failed to fetch subscription details: ${error.message}`,
  });

  useEffect(() => {
    trackEvent('CTMS: Viewed Subscription Details Page');
    if (
      subscription?.subscription?.status === 'active' ||
      subscription?.subscription?.status === 'in_trial'
    ) {
      hideLauncher();
    } else {
      showLauncher();
    }
    return showLauncher;
  }, [hideLauncher, showLauncher, subscription?.subscription?.status]);

  useEffect(() => {
    if (utm_source === 'email') {
      trackEvent('Carrier Clicked Upgrade Now', { utm_source });
    }
  }, [utm_source]);

  return (
    <>
      <DocumentTitle title="Subscription Details - Settings" />

      <SubscriptionUpdateCardDetailsDrawerRoute
        hasCardHolderName={true}
        onSubmitSuccess={() => {
          void refetchSubscriptionSettings();
        }}
        subscriptionDetails={subscription}
      />

      <SubscriptionUpdateCardDetailsDrawer
        open={isEditCardDetailsDrawerOpen}
        title="Edit Card Details"
        hasCardHolderName={false}
        onClose={() => {
          navigate('/settings/subscription', { replace: true });
        }}
        onSubmitSuccess={() => {
          void refetchSubscriptionSettings();
        }}
        subscriptionDetails={subscription}
      />

      <SubscriptionUpgradePlanDrawerRoute
        refetch={() => {
          void refetchSubscriptionSettings();
        }}
      />

      <SubscriptionReactivationDrawer
        open={isReactivatePlanDrawerOpen}
        onClose={() => {
          navigate('/settings/subscription', { replace: true });
        }}
      />

      <EditBillingAddress />

      <SettingsPageLayout
        actions={
          !isDriverSeat && (
            <Link
              target="_blank"
              rel="noreferrer noopener"
              underline="hover"
              href="https://support.superdispatch.com/hc/articles/35612448514195-How-To-Manage-Your-Subscription-Plan-In-Carrier-TMS"
            >
              <Typography color="primary" variant="body1">
                Learn More
              </Typography>
            </Link>
          )
        }
        title="Subscription Details"
        loading={isFetching}
      >
        <Box width="100%" maxWidth="950px">
          {isLoading && (
            <Stack space="small">
              <LoadingCard />
              <LoadingCard />
            </Stack>
          )}

          {!subscription && !isFetching && (
            <Card aria-label="No Subscription Details">
              <CardContent>
                <Typography variant="body1" gutterBottom={true}>
                  There are no subscription details.
                </Typography>
                <Typography gutterBottom={true}>
                  If you are in a trial period, subscription details will appear
                  after you subscribe.
                </Typography>
                If you are already a subscribed user, please contact support
              </CardContent>
            </Card>
          )}

          <Stack space="small">
            <SubscriptionCelebration />

            {subscription?.customer?.payment_method?.card?.masked_number ? (
              <SubscriptionDetailsCard />
            ) : (
              <SubscriptionPaymentForm />
            )}
          </Stack>
        </Box>
      </SettingsPageLayout>
    </>
  );
}
