import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscription } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BackButton } from 'shared/ui/BackButton';
import { Countdown } from 'shared/ui/Countdown';
import { MenuButton } from 'shared/ui/MenuButton';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import {
  useReactivateDispatcher,
  useResendDispatcherInvite,
} from '../data/DispatchersAPI';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatcherInviteCancelDialog } from '../dispatcher-invitation/DispatcherInvitationCancelDialog';
import { DispatcherDeactivationDialog } from './DispatcherDeactivationDialog';
import { DispatcherDeletionDialog } from './DispatcherDeletionDialog';
import { DispatcherDetailsMenuButton } from './DispatcherDetailMenuButton';
import { DispatcherStatus } from './DispatcherStatus';

export type DispatcherPageMutationDialogType =
  | 'delete'
  | 'deactivate'
  | 'plan_upgrade';

interface ResendDispatcherProps {
  guid: string;
  retryDate: DateTime;
}

export function DispatchersPageDetailsHeader() {
  const navigate = useNavigate();
  const isMobile = useResponsiveValue(true, false);
  const { dispatcher } = useDispatcherPageContext();
  const { data: currentDispatcher } = dispatcher;
  const isInvitedDispatcher =
    currentDispatcher?.connection_status === 'pending' &&
    currentDispatcher.connection_type === 'invite';

  const dispatcherLabel = isInvitedDispatcher
    ? currentDispatcher.email
    : currentDispatcher?.name;

  if (!currentDispatcher) {
    return (
      <TextBox variant="heading-2">
        <Skeleton width="200px" />
      </TextBox>
    );
  }

  if (isMobile) {
    return (
      <>
        <DocumentTitle title={`${dispatcherLabel} - Dispatchers`} />
        <Columns align="center" space="xsmall">
          <Column width="content">
            <BackButton
              onClick={() => {
                navigate('/dispatchers', { replace: true });
              }}
            />
          </Column>
          <Column width="fluid">
            <Inline>
              <TextBox variant="heading-2" noWrap={true}>
                {dispatcherLabel}
              </TextBox>

              <DispatcherStatus dispatcher={currentDispatcher} />
            </Inline>
          </Column>

          <Column width="content">
            <DispatcherPageDetailsHeaderActions />
          </Column>
        </Columns>
      </>
    );
  }

  return (
    <>
      <DocumentTitle title={`${dispatcherLabel} - Dispatchers`} />

      <Columns>
        <Column width="fluid">
          <Inline space="xsmall" noWrap={true} verticalAlign="center">
            <TextBox variant="heading-2" noWrap={true}>
              {dispatcherLabel}
            </TextBox>

            <DispatcherStatus dispatcher={currentDispatcher} />
          </Inline>
        </Column>

        <Column>
          <Inline space="small" horizontalAlign="right">
            <DispatcherPageDetailsHeaderActions />
          </Inline>
        </Column>
      </Columns>
    </>
  );
}

interface ResendInviteTooltipProps {
  seconds?: string;
}

function ResendInviteTooltip({ seconds }: ResendInviteTooltipProps) {
  return (
    <Typography>Wait {seconds} seconds before resending the invite.</Typography>
  );
}

function DispatcherPageDetailsHeaderActions() {
  const isMobile = useResponsiveValue(true, false);
  const { addSnackbar } = useSnackbarStack();
  const { isDriverSeat } = useSubscription();
  const [currentModal, setCurrentModal] =
    useState<DispatcherPageMutationDialogType | null>(null);
  const [isCancelInvite, setIsCancelInvite] = useState(false);
  const nextPlanEstimate = useNextPlanEstimate();
  const [resentDispatchers, setResentDispatchers] = useState<
    ResendDispatcherProps[]
  >([
    {
      guid: '',
      retryDate: DateTime.local(),
    },
  ]);
  const { dispatcher, currentDispatcherGUID } = useDispatcherPageContext();
  const shouldShowDispatcherDeactivation = useFlag(
    'dispatcher_management_deactivation',
  );

  const { data: currentDispatcher, isFetching: isDispatcherLoading } =
    dispatcher;

  const { mutate: resendInvite, isLoading } = useResendDispatcherInvite({
    onSuccess: () => {
      addSnackbar('Invite sent', { variant: 'success' });
      if (currentDispatcherGUID) {
        onResendInvite(currentDispatcherGUID);
      }
    },
  });

  const { mutate: reactivateDispatcher, isLoading: isReactivating } =
    useReactivateDispatcher({
      onSuccess: () => {
        addSnackbar(`${dispatcher.data?.name || 'Dispacher'} reactivated`, {
          variant: 'success',
        });
        trackDispatchersEvent({ name: 'Carrier Reactivated Dispatcher' });
      },
    });

  const resentDispatcher = useMemo(() => {
    return resentDispatchers.find(
      (resend) => resend.guid === currentDispatcherGUID,
    );
  }, [resentDispatchers, currentDispatcherGUID]);

  function handleResendInvite() {
    if (currentDispatcherGUID) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        if (nextPlan?.plan && !isDriverSeat) {
          setCurrentModal('plan_upgrade');
        } else {
          resendInvite(currentDispatcherGUID);
        }
      });
    }
  }

  function handleReactivateDispatcher() {
    if (currentDispatcherGUID) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        if (nextPlan?.plan && !isDriverSeat) {
          setCurrentModal('plan_upgrade');
        } else {
          reactivateDispatcher(currentDispatcherGUID);
        }
      });
    }
  }

  useEffect(() => {
    if (
      currentDispatcherGUID &&
      resentDispatcher &&
      resentDispatcher.retryDate < DateTime.local()
    ) {
      setResentDispatchers((prevResendDispatchers) =>
        prevResendDispatchers.filter(
          (resend) => resend.guid !== currentDispatcherGUID,
        ),
      );
    }
  }, [resentDispatcher, currentDispatcherGUID]);

  function onResendInvite(guid: string) {
    setResentDispatchers((prevResendDispatchers) => [
      ...prevResendDispatchers,
      {
        guid,
        retryDate: DateTime.local().plus({ seconds: 32 }),
      },
    ]);
  }

  if (!currentDispatcher) {
    return null;
  }

  return (
    <>
      <PlanUpdateDrawer
        open={currentModal === 'plan_upgrade' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(null);
        }}
        onSubmitSuccess={() => {
          if (currentDispatcherGUID) {
            resendInvite(currentDispatcherGUID);
          }
          setCurrentModal(null);
        }}
      />
      {currentDispatcher.connection_status === 'pending' &&
        currentDispatcher.connection_type === 'invite' && (
          <Countdown targetDate={resentDispatcher?.retryDate}>
            {(timeLeft, isElapsed) => {
              const seconds = timeLeft?.toFormat('s');

              return (
                <Tooltip
                  title={
                    !isElapsed &&
                    !!resentDispatcher?.guid &&
                    seconds !== '0' &&
                    !isDispatcherLoading &&
                    !isLoading ? (
                      <ResendInviteTooltip seconds={seconds} />
                    ) : (
                      ''
                    )
                  }
                >
                  <Box>
                    {isMobile ? (
                      <MenuButton
                        size="small"
                        aria-label="dispatcher invite options"
                        disabled={
                          (!isElapsed && !!resentDispatcher?.guid) ||
                          isDispatcherLoading
                        }
                        renderMenu={({ onClose, ...menuProps }) => (
                          <Menu {...menuProps} onClose={onClose}>
                            <MenuItem
                              key="resend"
                              onClick={() => {
                                onClose();
                                handleResendInvite();
                              }}
                            >
                              <TextBox>Resend Invite</TextBox>
                            </MenuItem>
                            <MenuItem
                              key="cancel-invite"
                              onClick={() => {
                                onClose();

                                setIsCancelInvite(true);
                              }}
                            >
                              <TextBox>Cancel Invite</TextBox>
                            </MenuItem>
                          </Menu>
                        )}
                      >
                        <MoreHoriz />
                      </MenuButton>
                    ) : (
                      <Inline space="small">
                        <Button
                          disabled={
                            (!isElapsed && !!resentDispatcher?.guid) ||
                            isDispatcherLoading
                          }
                          pending={isLoading}
                          onClick={() => {
                            handleResendInvite();
                          }}
                        >
                          Resend Invite
                        </Button>
                        <Button
                          variant="neutral"
                          disabled={isDispatcherLoading}
                          onClick={() => {
                            setIsCancelInvite(true);
                          }}
                        >
                          Cancel Invite
                        </Button>
                      </Inline>
                    )}
                  </Box>
                </Tooltip>
              );
            }}
          </Countdown>
        )}

      {(currentDispatcher.connection_status === 'activated' ||
        currentDispatcher.connection_status === 'deactivated') &&
        shouldShowDispatcherDeactivation && (
          <DispatcherDetailsMenuButton
            onSelect={setCurrentModal}
            isReactivating={isReactivating}
            onReactivate={handleReactivateDispatcher}
            connectionStatus={currentDispatcher.connection_status}
          />
        )}

      <DispatcherInviteCancelDialog
        dispatcher={currentDispatcher}
        open={isCancelInvite}
        onClose={() => {
          setIsCancelInvite(false);
        }}
      />

      <DispatcherDeactivationDialog
        open={currentModal === 'deactivate'}
        onClose={() => {
          setCurrentModal(null);
        }}
      />

      <DispatcherDeletionDialog
        open={currentModal === 'delete'}
        onClose={() => {
          setCurrentModal(null);
        }}
      />
    </>
  );
}
