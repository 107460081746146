import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  ColorDynamic,
  Column,
  Columns,
  DrawerContent,
  DrawerTitle,
  Inline,
  Stack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { ChargebeeCreditCard } from 'shared/helpers/ChargebeeWeb';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import { trackSubscriptionEvent } from './data/SubscriptionAnalytics';
import {
  useEstimateTaxPrice,
  useSubscription,
  useUpdateSubscriptionSettingsMutation,
} from './SubscriptionAPI';

export interface LocationState {
  token: string;
  vaultToken: string;
  billingAddress: {
    zip: string;
    country: string;
    state: string;
  };
  cardDetails: ChargebeeCreditCard | undefined;
  seatsCount?: number;
}

export interface SubscriptionSalesTaxDrawer extends LocationState {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubscriptionSalesTaxDrawer({
  open,
  onClose,
  billingAddress,
  cardDetails,
  token,
  vaultToken,
  seatsCount,
  onSubmitSuccess,
}: SubscriptionSalesTaxDrawer) {
  const { isDriverSeat, seatType } = useSubscription();
  const { data: taxPrice, isInitialLoading } = useEstimateTaxPrice({
    seats_count: seatsCount,
    include_total_invoice: true,
  });
  const {
    mutate: updateSubscription,
    isLoading,
    error,
  } = useUpdateSubscriptionSettingsMutation({
    onSuccess: () => {
      onClose();
      onSubmitSuccess?.();
      trackSubscriptionEvent({
        name: 'Carrier Upgraded Plan',
      });
    },
  });

  const country = getCountryName(billingAddress.country as CountryFieldOption);
  const hasCreditCard = !!cardDetails?.creditCard;

  useEffect(() => {
    trackSubscriptionEvent({
      name: 'Carrier Opened Subscription Checkout',
    });
  }, []);

  return (
    <Drawer open={open} onClose={onClose} title="Upgrading Plan">
      <DrawerTitle
        title="Upgrading Plan"
        endAction={
          <IconButton edge="end" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <DrawerContent>
        {isInitialLoading ? (
          <Stack space="small">
            <LoadingCard />
            <LoadingCard />
          </Stack>
        ) : (
          <Stack space="medium">
            <Box paddingBottom="small">
              <Stack space="large">
                {hasCreditCard && (
                  <Stack space="xsmall">
                    <Typography color="textSecondary" variant="h6">
                      CARD DETAILS
                    </Typography>
                    <Typography color="textSecondary">
                      Card Number: ••••{' '}
                      {cardDetails.creditCard.details.lastFour}
                    </Typography>
                    <Typography color="textSecondary">
                      Expiration Date:{' '}
                      {cardDetails.creditCard.details.expirationMonth} /{' '}
                      {cardDetails.creditCard.details.expirationYear}
                    </Typography>
                  </Stack>
                )}

                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    Billing Address
                  </Typography>
                  <Typography color="textSecondary">
                    {joinStrings(
                      ', ',
                      billingAddress.state + ' ' + billingAddress.zip,
                      country,
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            <Stack space="small">
              {!!taxPrice?.plan && (
                <Stack space="small">
                  <Stack space="xxsmall">
                    <DescriptionLineItem
                      title={
                        <TextBox variant="body" color="primary">
                          Pro plan
                        </TextBox>
                      }
                    >
                      <TextBox align="right" color="primary">
                        {formatCurrency(taxPrice?.plan.amount)}
                      </TextBox>
                    </DescriptionLineItem>

                    <TextBox color="secondary">
                      {formatCurrency(Number(taxPrice?.plan.unit_price))} /{' '}
                      {seatType} / month x {seatsCount}{' '}
                      {isDriverSeat
                        ? formatPlural(seatsCount, 'driver', 'drivers')
                        : formatPlural(seatsCount, 'seat', 'seats')}
                    </TextBox>
                    <TextBox color="secondary">Billed Monthly</TextBox>
                  </Stack>
                </Stack>
              )}

              <Stack space="xsmall">
                <DescriptionLineItem
                  title={
                    <TextBox variant="body" color="primary">
                      Subtotal
                    </TextBox>
                  }
                >
                  <TextBox align="right" color="primary">
                    {formatCurrency(taxPrice?.sub_total)}
                  </TextBox>
                </DescriptionLineItem>
                {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                  <Columns>
                    <Column>
                      <Box />
                    </Column>
                    <Column width="2/3">
                      <Stack align="right" space="xxsmall">
                        {taxPrice.taxes.map((tax) => {
                          return (
                            <DescriptionLineItem
                              key={tax.name}
                              title={
                                <TextBox color="secondary">
                                  {tax.description}
                                </TextBox>
                              }
                            >
                              <TextBox align="right" color="secondary">
                                {formatCurrency(tax.amount)}
                              </TextBox>
                            </DescriptionLineItem>
                          );
                        })}
                      </Stack>
                    </Column>
                  </Columns>
                )}
              </Stack>

              <DescriptionLineItem
                title={<TextBox variant="heading-4">Total</TextBox>}
              >
                <TextBox variant="heading-4" align="right">
                  {formatCurrency(taxPrice?.total)}
                </TextBox>
              </DescriptionLineItem>
            </Stack>
            <Box paddingTop="xsmall">
              <Button
                pending={isLoading}
                onClick={() => {
                  updateSubscription({
                    payment_method_nonce: token,
                    id: token,
                    id_at_vault: vaultToken,
                    seats_count: seatsCount,
                  });
                }}
                size="large"
                fullWidth={true}
              >
                Upgrade Now
              </Button>
            </Box>
          </Stack>
        )}
      </DrawerContent>

      {error && !isLoading && (
        <Box maxWidth="430px" paddingRight="large" paddingLeft="large">
          <Alert
            icon={<Info fontSize="small" htmlColor={ColorDynamic.Red500} />}
            severity="critical"
          >
            {error.message}
          </Alert>
        </Box>
      )}
    </Drawer>
  );
}

function getCountryName(countryCode: CountryFieldOption) {
  return countryFieldOptions.get(countryCode);
}

function LoadingCard() {
  return (
    <Stack space="small">
      <Inline verticalAlign="bottom">
        <Typography display="inline" variant="h3">
          <Skeleton width={200} />
        </Typography>
      </Inline>
      <Stack space={['small', 'xsmall']}>
        <Skeleton width={240} />
        <Skeleton />
        <Skeleton />
      </Stack>
    </Stack>
  );
}
