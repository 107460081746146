import { Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  DrawerActions,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { useState } from 'react';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import { trackSubscriptionEvent } from '../data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useEstimateSeatsUpgrade,
  useSubscription,
  useSubscriptionAPI,
} from '../SubscriptionAPI';
import { PlanUpdateDrawerLoadingContent } from './PlanUpdateDrawerLoadingContent';
import { SeatBasedPricingBox } from './SeatBasedPricingBox';

export function useNextPlanEstimate() {
  const { billableSeats } = useSubscription();

  const seatsCount = billableSeats?.reserved_seats
    ? Number(billableSeats?.reserved_seats) + 1
    : 1;

  return useEstimateSeatsUpgrade(seatsCount, {
    enabled: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
  });
}

interface PlanUpdateDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function PlanUpdateDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: PlanUpdateDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <PlanUpdateDrawerContent
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
      />
    </FormikDrawer>
  );
}

interface PlanUpdateDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
}

function PlanUpdateDrawerContent({
  onClose,
  onSubmitSuccess,
}: PlanUpdateDrawerContentProps) {
  const { data: billingAddress, isInitialLoading: isBillingAddressLoading } =
    useBillingAddress();
  const {
    subscription: subscriptionDetails,
    isSubscriptionSettingsLoading,
    billableSeats,
    isDriverSeat,
    seatType,
  } = useSubscription();
  const { upgradeSeats } = useSubscriptionAPI();
  const { addSnackbar } = useSnackbarStack();
  const [errorMsg, setErrorMsg] = useState<string>();

  const purchasedSeatsCount = Number(
    subscriptionDetails?.subscription?.plan?.quantity,
  );
  const billableSeatsCount = Number(billableSeats?.billable_seats);
  const initialSeatsCount =
    purchasedSeatsCount > billableSeatsCount
      ? billableSeatsCount
      : purchasedSeatsCount + 1;

  const formik = useFormikEnhanced({
    initialValues: {
      seats_count: initialSeatsCount,
    },
    onSubmit(values) {
      return upgradeSeats(values.seats_count);
    },
    validate: ({ seats_count }) => {
      if (
        !seats_count ||
        seats_count < (billableSeats?.billable_seats || 1) + 1
      ) {
        return {
          seats_count:
            'Please enter a number greater than the current number of drivers',
        };
      }
      return {};
    },
    onSubmitSuccess() {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const totalPrice = seatsUpgrade?.plan?.amount;
      trackSubscriptionEvent({ name: 'Carrier Updated Seats' });
      addSnackbar(
        `Plan updated to ${formik.values.seats_count} ${
          isDriverSeat ? 'drivers' : 'seats'
        } ${formatCurrency(totalPrice)}/month`,
        {
          variant: 'success',
        },
      );

      onSubmitSuccess();
    },
    onSubmitFailure: (error) => {
      setErrorMsg(error.message);
    },
  });

  const { values } = formik;
  const { data: seatsUpgrade } = useEstimateSeatsUpgrade(values.seats_count);
  const seatsCount = billableSeats?.billable_seats || 1;
  const hasCreditCard = !!subscriptionDetails?.customer?.payment_method?.card;
  const cardDetails = subscriptionDetails?.customer?.payment_method?.card;
  const isDisabledMinus = formik.values.seats_count <= seatsCount + 1;

  const isLoading = isSubscriptionSettingsLoading || isBillingAddressLoading;
  const debouncedSeatsCount = useDebouncedValue(values.seats_count, 500);

  return (
    <FormikDrawerContent
      onClose={onClose}
      formik={formik}
      title="Update Your Plan?"
      actions={
        !isLoading && (
          <PlanUpdateDrawerActions
            seats_count={debouncedSeatsCount}
            isSubmitting={formik.isSubmitting}
            errorMsg={errorMsg}
          />
        )
      }
    >
      {isLoading ? (
        <PlanUpdateDrawerLoadingContent />
      ) : (
        <Box maxWidth="430px">
          <Stack space="large">
            <Stack space="small">
              <Box
                padding="xsmall"
                borderRadius="small"
                backgroundColor="Blue50"
              >
                <Columns align="top" space="xsmall">
                  <Column width="content">
                    <Info fontSize="small" htmlColor={Color.Blue300} />
                  </Column>
                  <Column width="fluid">
                    <Stack space="xxsmall">
                      <Typography variant="body1">
                        Current Carrier TMS Plan: {seatsCount}{' '}
                        {isDriverSeat
                          ? seatsCount > 1
                            ? 'Drivers'
                            : 'Driver'
                          : seatsCount > 1
                          ? 'Seats'
                          : 'Seat'}
                      </Typography>
                      <Typography>
                        Inviting a new {seatType} will update your plan. <br />
                        To avoid changing your plan, replace a {seatType} by
                        deactivating any {seatType} first and then inviting.
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Box>
              <SeatBasedPricingBox
                isMinusDisabled={isDisabledMinus}
                isReadOnly={true}
              />
            </Stack>

            <Box paddingBottom="small">
              <Stack space="large">
                {hasCreditCard && (
                  <Stack space="xsmall">
                    <Typography color="textSecondary" variant="h6">
                      CARD DETAILS
                    </Typography>
                    <Typography color="textSecondary">
                      Card Number: {cardDetails?.masked_number}
                    </Typography>
                    <Typography color="textSecondary">
                      Expiration Date: {cardDetails?.expiry_month} /{' '}
                      {cardDetails?.expiry_year}
                    </Typography>
                  </Stack>
                )}

                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    Billing Address
                  </Typography>
                  <Typography color="textSecondary">
                    {joinStrings(
                      ', ',
                      billingAddress?.billing_address,
                      joinStrings(
                        ' ',
                        billingAddress?.billing_city,
                        billingAddress?.billing_state,
                        billingAddress?.billing_zip,
                      ),
                      billingAddress?.billing_country,
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </FormikDrawerContent>
  );
}

interface PlanUpdateDrawerActionsProps {
  isSubmitting: boolean;
  seats_count: number;
  errorMsg?: string;
}

function PlanUpdateDrawerActions({
  isSubmitting,
  seats_count,
  errorMsg,
}: PlanUpdateDrawerActionsProps) {
  const { data: taxPrice, isLoading } = useEstimateSeatsUpgrade(seats_count);

  return (
    <DrawerActions>
      <Stack space="medium">
        <Stack space="large">
          <Stack space="small">
            <Stack space="xxsmall">
              <DescriptionLineItem
                title={
                  <TextBox variant="body" color="primary">
                    Plan Update
                  </TextBox>
                }
              >
                {isLoading ? (
                  <Skeleton width="60px" height="20px" />
                ) : (
                  <TextBox align="right" color="primary">
                    {formatCurrency(taxPrice?.sub_total)}
                  </TextBox>
                )}
              </DescriptionLineItem>

              <TextBox color="secondary">Prorated</TextBox>
            </Stack>

            <Stack space="xsmall">
              <DescriptionLineItem
                title={
                  <TextBox variant="body" color="primary">
                    Subtotal
                  </TextBox>
                }
              >
                {isLoading ? (
                  <Skeleton width="60px" height="20px" />
                ) : (
                  <TextBox align="right" color="primary">
                    {formatCurrency(taxPrice?.sub_total)}
                  </TextBox>
                )}
              </DescriptionLineItem>
              {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                <Columns>
                  <Column>
                    <Box />
                  </Column>
                  <Column width="2/3">
                    <Stack align="right" space="xxsmall">
                      {taxPrice.taxes.map((tax) => {
                        return (
                          <DescriptionLineItem
                            key={tax.name}
                            title={
                              <TextBox color="secondary">
                                {tax.description}
                              </TextBox>
                            }
                          >
                            <TextBox align="right" color="secondary">
                              {formatCurrency(tax.amount)}
                            </TextBox>
                          </DescriptionLineItem>
                        );
                      })}
                    </Stack>
                  </Column>
                </Columns>
              )}
            </Stack>

            <DescriptionLineItem
              title={<TextBox variant="heading-4">Total Due Today</TextBox>}
            >
              {isLoading ? (
                <Skeleton width="64px" height="24px" />
              ) : (
                <TextBox variant="heading-4" align="right">
                  {formatCurrency(taxPrice?.total)}
                </TextBox>
              )}
            </DescriptionLineItem>
          </Stack>

          <Button
            size="large"
            fullWidth={true}
            type="submit"
            pending={isSubmitting}
          >
            Update Now
          </Button>
        </Stack>
        {errorMsg && (
          <Box maxWidth="430px">
            <Alert severity="critical">{errorMsg}</Alert>
          </Box>
        )}
      </Stack>
    </DrawerActions>
  );
}
